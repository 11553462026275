export function groupBy(collection, property) {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}

export function getAllLanguageFromData(data) {
    return (
        data?.LSF.map(lang => { return Object.keys(lang)[0] })
    )
}
export function processErrorResponse(response) {
    if (response.status == 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('Name');
        localStorage.removeItem('Role');
        localStorage.removeItem('Location');
        return null;
    }
}