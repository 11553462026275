
import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";

function Loader({ loader }) {
    return (
        loader ? (
            <LoadingOverlay
                active={loader}
                spinner
                styles={{
                    wrapper: {
                        height: "100%",
                        width: "100%",
                        position: "fixed",
                        zIndex: "50000",
                        top: "0",
                        background: "rgba(255, 255, 255, 0.5)"
                    }
                }}
            ></LoadingOverlay>
        ) : (
                ""
            )
    );
}

const mapStateToProps = ({ reports, questions, auth }) => {
    const reportsLoader = reports.loader;
    const questionsLoader = questions.loader;
    const authLoader = auth.loader;
    const loader = authLoader || reportsLoader || questionsLoader
    return {
        loader
    };
};


export default connect(mapStateToProps)(Loader);
