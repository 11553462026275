import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    SHOW_SUCCESS_MESSAGE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS
} from 'constants/ActionTypes';

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
export const changePassword = passObj => {
    return {
        type: CHANGE_PASSWORD,
        passObj
    };
};
export const changePasswordSuccess = msg => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        msg
    };
};
export const forgotPassword = emailObj => {
    return {
        type: FORGOT_PASSWORD,
        emailObj
    };
};
export const forgotPasswordSuccess = msg => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        msg
    };
};
export const resetPassword = Obj => {
    return {
        type: RESET_PASSWORD,
        Obj
    };
};
export const resetPasswordSuccess = msg => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        msg
    };
};
export const showSuccess = message => {
    return {
        type: SHOW_SUCCESS_MESSAGE,
        payload: message
    };
};