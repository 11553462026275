import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignIn,
    userTwitterSignIn
} from 'actions/Auth';

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDisable, setDisable] = useState(true);
    const dispatch = useDispatch();
    const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            props.history.push('/');
        }
    }, [showMessage, authUser, props.history, dispatch]);

    useEffect(() => {
        if (email.length >= 3 && password.length >= 3) {
            setDisable(false)
        }
        else {
            setDisable(true)
        }
    }, [email, password]);


    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 w-100 h-100">
            <div className="app-login-main-content">

                <div className="app-logo-content d-flex align-items-center justify-content-center">
                    <Link className="logo-lg" to="/" title="ICMR">
                        <img src={require("assets/images/logo.png")} alt="ICMR" title="ICMR" style={{ width: '100%', background: "#fff" }} />
                    </Link>
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1><IntlMessages id="appModule.email" /></h1>
                    </div>

                    <div className="app-login-form">
                        <form>
                            <fieldset>
                                <TextField
                                    label={<IntlMessages id="appModule.email" />}
                                    fullWidth
                                    onChange={(event) => setEmail(event.target.value)}
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <TextField
                                    type="password"
                                    label={<IntlMessages id="appModule.password" />}
                                    fullWidth
                                    onChange={(event) => setPassword(event.target.value)}
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button onClick={() => {
                                        dispatch(showAuthLoader());
                                        dispatch(userSignIn({ email, password }));
                                    }} variant="contained"
                                        disabled={isDisable}
                                        color="primary">
                                        <IntlMessages id="appModule.signIn" />
                                    </Button>

                                    <Link to="/forgotPassword">
                                        <IntlMessages id="appModule.forgotPassword" />
                                    </Link>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>

            </div>
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer />
        </div>
    );
};


export default SignIn;
