import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';


const Routes = ({ match }) =>
    <Switch>
        {/* <Route path={`${match.url}/sample-page`}
            component={asyncComponent(() => import('./homePage'))} /> */}
        <Route path={`${match.url}/dashboard`}
            component={asyncComponent(() => import('./homePage'))} />
        <Route path={`${match.url}/question`}
            component={asyncComponent(() => import('./Question'))} />
        <Route path={`${match.url}/Reports`}
            component={asyncComponent(() => import('./Reports'))} />
        <Route path={`${match.url}/User`}
            component={asyncComponent(() => import('./User'))} />
        {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
    </Switch>;


export default withRouter(Routes);

