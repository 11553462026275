import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";

const SideBarContent = () => {
    const navigationMenus = [
        {
            name: 'sidebar.main',
            type: 'section',
            children: [
                {
                    name: 'sidebar.dashboard',
                    icon: 'view-dashboard',
                    type: 'item',
                    link: '/app/dashboard'

                }
            ]
        },
        {
            name: 'Questions',
            type: 'section',
            children: [
                {
                    name: 'Questions',
                    icon: 'comments',
                    type: 'item',
                    link: '/app/question'

                }
            ]
        },
        {
            name: 'Reports',
            type: 'section',
            children: [
                {
                    name: 'Reports',
                    icon: 'view-list',
                    type: 'collapse',
                    children: [
                        {
                            // navrbar
                            name: 'Daily Report',
                            type: 'item',
                            link: '/app/Reports/DailyReport',
                            icon: 'file',

                        },
                        {
                            name: 'State Report',
                            type: 'item',
                            link: '/app/Reports/StateReport',
                            icon: 'file',
                        }
                    ]

                }
            ]
        },

    ];

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus} />
        </CustomScrollbars>
    );
};

export default SideBarContent;
