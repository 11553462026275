import {
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  GET_ALL_REPORTS,
  GET_ALL_REPORTS_SUCCESS,
  GET_REPORT_BY_SITE,
  GET_REPORT_BY_SITE_SUCCESS,
  GET_REPORT_BY_DATE,
  GET_REPORT_BY_DATE_SUCCESS
} from "constants/ActionTypes";
import FileSaver from "file-saver";

const INIT_STATE = {
  allReportss: [],
  loader: false,
  blobData: null,
  reportsBySite: [],
  reportsByDate: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_REPORTS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ALL_REPORTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        allReportss: action.payload
      };
    }
    case GET_REPORT_BY_SITE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_REPORT_BY_SITE_SUCCESS: {
      return {
        ...state,
        loader: false,
        reportsBySite: action.payload
      };
    }
    case GET_REPORT_BY_DATE: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_REPORT_BY_DATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        reportsByDate: action.payload
      };
    }

    case DOWNLOAD_REPORT: {
      return {
        ...state,
        loader: true,
      };
    }
    case DOWNLOAD_REPORT_SUCCESS: {
      // FileSaver.saveAs(new Blob([action.reportBlogData], {
      //   type: "application/octet-stream"
      // }),'tutorials.xlsx'
      // // `${action.stateCode}-patient-report-${new Date().toISOString().slice(0,10)}.xlsx`
      // );
      console.log('heml', action.reportBlogData)

      // FileSaver.saveAs(
      //   new Blob([action.reportBlogData], {
      //     type: "application/octet-stream"
      //   })
      //   ,`${action.stateCode}-patient-report-${new Date().toISOString().slice(0,10)}.xlsx`
      // );
      FileSaver.saveAs(
        new Blob([action.reportBlogData], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }),
        'excel.xlsx'
      );
      return {
        ...state,
        loader: false
        // blobData: action.reportBlogData
      }
    }

    default:
      return state;
  }
}