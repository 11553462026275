import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from "./Common";
import Questions from "./QuestionsReducer";
import Reports from "./ReportsReducer";


export default (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    questions: Questions,
    reports: Reports
});
