import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getAllQuestionsSuccess, getAllQuestionsTypeSuccess, getAllLanguageSuccess, addQuestionSuccess, getQuestionsByIdSuccess, deleteQuestionSuccess, editQuestionSuccess, showQuestionErrorMessage, showQuestionSuccessMessage, getAllQuestions, getFormTypesSuccess, getSectionsSuccess, getAllDataSourcesSuccess } from "actions/QuestionsAction";
import { GET_ALL_QUESTIONS, GET_ALL_QUESTIONS_TYPE, GET_ALL_LANGUAGE, POST_ALL_QUESTIONS, GET_ALL_QUESTIONS_BY_ID, DELETE_QUESTION, EDIT_QUESTIONS, GET_FORM_TYPES, GET_SECTIONS, GET_ALL_DATA_SOURCES } from "constants/ActionTypes";
import url from "util/url";
import { BASE_API_URL } from "util/global";


export function getAllQuestionsApi() {
  console.log("BASE_API_URL", BASE_API_URL);
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_QUESTIONS
      // headers:http://75e4e0461a10.ngrok.io
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
function* fetchAllQuestions() {
  console.log("Calling fetchAllQuestions wiht:-");
  try {
    const response = yield call(getAllQuestionsApi);
    console.log("fetchAllQuestions", response.data);
    if (response.data.error) {
    } else {
      yield put(getAllQuestionsSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}






export function getAllQuestionsType() {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_QUESTIONS_TYPE
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET"
      // }
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
function* fetchAllQuestionsType() {
  // console.log("Calling fetch All QuestionsType wiht:-");
  try {
    const response = yield call(getAllQuestionsType);
    // console.log("fetchAllQuestionsType", response.data);
    if (response.data.error) {
    } else {
      yield put(getAllQuestionsTypeSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}



export function getAllLanguage() {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_LANGUAGE
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET"
      // }
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
function* fetchAllLanguage() {
  console.log("Calling fetch All Language wiht:-");
  try {
    const response = yield call(getAllLanguage);
    console.log("fetchAllLanguage", response.data);
    if (response.data.error) {
    } else {
      yield put(getAllLanguageSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}



export function addQuestionApi(addQuestionObj) {
  console.log("addQuestionApi = ", addQuestionObj);
  try {
    return axios({
      method: "post",
      url: BASE_API_URL + url.POST_ALL_QUESTIONS,
      data: addQuestionObj,
      // headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
  } catch (error) {
    throw error;
  }
}
function* addQuestionsToServer(action) {
  console.log("addQuestionsToServer = ", action);
  try {
    const response = yield call(addQuestionApi, action.addQuestionObj);
    console.log("response", response);
    if (response.data.error) {
      yield put(showQuestionErrorMessage(response.data.message));
      console.log("response.data.error", response.data.error);
    } else {
      yield put(addQuestionSuccess(response.data));
      yield put(showQuestionSuccessMessage(response.data.message));
      //alert("Tour alert done");
    }
  } catch (error) {
    console.log("Exception", error.response);
    yield put(showQuestionErrorMessage(error.response.data.message));
  }
}

export function editQuestionApi(editQuestionObj) {
  console.log("editQuestionApi = ", editQuestionObj);
  try {
    return axios({
      method: "put",
      url: BASE_API_URL + url.EDIT_QUESTIONS,
      data: editQuestionObj,
      // headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
  } catch (error) {
    throw error;
  }
}
function* editQuestionsToServer(action) {
  console.log("editQuestionsToServer = ", action);
  try {
    const response = yield call(editQuestionApi, action.editQuestionObj);
    console.log("response", response);
    if (response.data.error) {
      yield put(showQuestionErrorMessage(response.data.message));
      console.log("response.data.error", response.data.error);
    } else {
      yield put(editQuestionSuccess(response.data));
      yield put(showQuestionSuccessMessage(response.data.message));
      //alert("Tour alert done");
    }
  } catch (error) {
    console.log("Exception", error.response);
  }
}

export function getQuestionsByIdApi(questions_id) {
  // console.log("questions_id in api => ", questions_id);


  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_QUESTIONS_BY_ID + "/" + questions_id
      // url: 'https://api.mocki.io/v1/a9750c3c'
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET"
      // }
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
function* fetchAllQuestionsById(action) {
  // console.log("Get all questions by id saga => ", action.questions_id);
  try {
    const response = yield call(getQuestionsByIdApi, action.questions_id);
    console.log("fetchAllQuestionsById => ", response.data);
    if (response.data.error) {
    } else {
      yield put(getQuestionsByIdSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}

export function deleteQuestionApi(question_id) {
  console.log("deleteQuestionApi => ", question_id);
  try {
    return axios({
      method: "delete",
      url: BASE_API_URL + url.DELETE_QUESTION + "/" + question_id,
      // data: addQuestionObj,
      // headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
  } catch (error) {
    throw error;
  }
}
function* deleteQuestionFromServer(action) {
  console.log("deleteQuestionFromServer => ", action.question_id);
  try {
    const response = yield call(deleteQuestionApi, action.question_id);
    console.log("response", response);
    if (response.data.error) {
      yield put(showQuestionErrorMessage(response.data.message));
      console.log("response.data.error", response.data.error);
    } else {
      yield put(deleteQuestionSuccess(response.data));
      yield put(showQuestionSuccessMessage(response.data.message));
      action.callBack();
      yield put(getAllQuestions());

    }
  } catch (error) {
    console.log("Exception", error.response);
  }
}

export function getFormTypesApi() {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_FORM_TYPES
      // headers:http://75e4e0461a10.ngrok.io
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* fetchFormTypes() {
  console.log("Calling fetchFormTypes => ");
  try {
    const response = yield call(getFormTypesApi);
    console.log("fetchFormTypes", response.data);
    if (response.data.error) {
    } else {
      yield put(getFormTypesSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}

export function getSectionsApi() {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_SECTIONS
      // headers:http://75e4e0461a10.ngrok.io
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* fetchSections() {
  console.log("Calling fetchSections => ");
  try {
    const response = yield call(getSectionsApi);
    console.log("fetchSections", response.data);
    if (response.data.error) {
    } else {
      yield put(getSectionsSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}
export function getAllDataSourcesApi() {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_DATA_SOURCES
      // headers:http://75e4e0461a10.ngrok.io
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* fetchgetAllDataSources() {
  console.log("Calling fetchgetAllDataSources => ");
  try {
    const response = yield call(getAllDataSourcesApi);
    console.log("fetchgetAllDataSources", response.data);
    if (response.data.error) {
    } else {
      yield put(getAllDataSourcesSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);
    // yield put(showLeaveErrorMessage(error.response.data.message));
    // if (error.response.status == 403)
    //yield put(userSignOutSuccess(processErrorResponse(error.response)));
  }
}
export function* fetchAllDataSourcesSaga() {
  yield takeEvery(GET_ALL_DATA_SOURCES, fetchgetAllDataSources);
}

export function* fetchAllQuestionsSaga() {
  yield takeEvery(GET_ALL_QUESTIONS, fetchAllQuestions);
}
export function* fetchAllQuestionsTypeSaga() {
  yield takeEvery(GET_ALL_QUESTIONS_TYPE, fetchAllQuestionsType);
}
export function* fetchAllLanguageSaga() {
  yield takeEvery(GET_ALL_LANGUAGE, fetchAllLanguage);
}
export function* sendQuestionToServerSaga() {
  yield takeEvery(POST_ALL_QUESTIONS, addQuestionsToServer);
}
export function* sendEditQuestionToServerSaga() {
  yield takeEvery(EDIT_QUESTIONS, editQuestionsToServer);
}
export function* fetchAllQuestionsByIdSaga() {
  yield takeEvery(GET_ALL_QUESTIONS_BY_ID, fetchAllQuestionsById);
}

export function* deleteQuestionFromServerSaga() {
  yield takeEvery(DELETE_QUESTION, deleteQuestionFromServer);
}

export function* fetchFormTypesSaga() {
  yield takeEvery(GET_FORM_TYPES, fetchFormTypes);
}

export function* fetchSectionsSaga() {
  yield takeEvery(GET_SECTIONS, fetchSections);
}

export default function* rootSaga() {
  yield all([

    fork(fetchAllQuestionsSaga),
    fork(fetchAllQuestionsTypeSaga),
    fork(fetchAllLanguageSaga),
    fork(sendQuestionToServerSaga),
    fork(fetchAllQuestionsByIdSaga),
    fork(deleteQuestionFromServerSaga),
    fork(sendEditQuestionToServerSaga),
    fork(fetchFormTypesSaga),
    fork(fetchSectionsSaga),
    fork(fetchAllDataSourcesSaga),
  ]);
}
