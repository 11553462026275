import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import configureStore, { history } from './store';
import App from './containers/App';
import Loader from 'components/Loader';

export const store = configureStore();

const MainApp = () =>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App} />
            </Switch>
            <Loader />
        </ConnectedRouter>
    </Provider>;

export default MainApp;