import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';
import { forgotPassword } from 'actions';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const [isDisable, setDisable] = useState(true);
  const { loader, alertMessage, showMessage,showSuccessMessage,successMessage } = useSelector(({ auth }) => auth);
  useEffect(() => {
    if (email.length >= 3) {
      setDisable(false)
    }
    else {
      setDisable(true)
    }
  }, [email]);
  console.log("showSuccessMessage",showSuccessMessage,successMessage);
  return (
    <div
      className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content">
        <div className="login-header">
          <Link className="app-logo" to="/" title="ICMR">
            <img src={require("assets/images/logo-color.png")} alt="jambo" title="jambo" style={{ width: '100%', background: "#fff", height: 'auto' }} />
          </Link>
        </div>

        <div className="mb-2">
          <h2><IntlMessages id="appModule.forgotPassword" /></h2>
        </div>

        <div className="login-form">
          <form>
            <TextField
              type="email"
              id="required"
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              defaultValue=""
              margin="normal"
              className="mt-0 mb-4"

            />

            <Button onClick={() => dispatch(forgotPassword({ email: email }))}
              disabled={isDisable || loader}
              variant="contained" color="primary" className="text-white">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </form>
        </div>
      </div>
      {showMessage && NotificationManager.error(alertMessage)}
      {showSuccessMessage && NotificationManager.success(successMessage)}
      <NotificationContainer />
    </div>
  );
};

export default ForgotPassword;

