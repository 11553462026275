import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

export default {
    palette: {
        primary: {
            light:"#23376d" ,
            main:"#23376d" ,
            dark:"#23376d" ,
            contrastText: '#fff'
        },
        secondary: {
            light:"#ffbb38" ,
            main: "#ffbb38",
            dark: "#ffbb38",
            contrastText: '#fff'
        }
    },
    status: {
        danger: 'red',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    },
};
