import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';
import { Formik } from 'formik';
import { showAuthLoader, hideMessage, resetPassword } from 'actions/Auth';
import SweetAlert from "react-bootstrap-sweetalert";

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const { alertMessage, showMessage, authUser, showSuccessMessage, successMessage } = useSelector(({ auth }) => auth);
    console.log("props of in", props?.match?.params?.slug);
    const slug = props?.match?.params?.slug;
    return (
        <div
            className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="login-content">
                <div className="login-header">
                    <Link className="app-logo" to="/" title="ICMR">
                        <img src={require("assets/images/logo-color.png")} alt="ICMR" title="ICMR" style={{ width: '100%', background: "#fff", height: 'auto' }} />
                    </Link>
                </div>

                <div className="mb-2 text-center">
                    <h2><IntlMessages id="appModule.resetPassword" /></h2>
                </div>
                <Formik
                    initialValues={{ password: '', newPassword: '' }}
                    validate={values => {
                        const errors = {};
                        if (!values.password) {
                            errors.password = 'Required';
                        } if (!values.newPassword) {
                            errors.newPassword = 'Required';
                        } else if (values.newPassword !== values.password) {
                            errors.newPassword = 'Password not match';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        dispatch(showAuthLoader());
                        dispatch(resetPassword({ resetLink: slug, newPass: values.newPassword }));
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    type="password"
                                    id="newPass"
                                    name="password"
                                    label={<IntlMessages id="appModule.Newpassword" />}
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                <text className='text-danger'>
                                    {errors.password && touched.password && errors.password}
                                </text>
                                <TextField
                                    type="password"
                                    id="conpass"
                                    name="newPassword"
                                    label={<IntlMessages id="appModule.confirmpassword" />}
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPassword}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                <text className='text-danger'>
                                    {errors.newPassword && touched.newPassword && errors.newPassword}
                                </text>
                                <div className='text-center'>
                                    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" className="text-white">
                                        <IntlMessages id="appModule.resetPassword" />
                                    </Button>
                                </div>
                            </form>
                        )}
                </Formik>


            </div>
            {showMessage && <SweetAlert
                show={showMessage}
                danger
                title={alertMessage}
                onConfirm={() => dispatch(hideMessage())}
            />}
            {showSuccessMessage && <SweetAlert
                show={showSuccessMessage}
                success
                title={successMessage}
                onConfirm={() => {
                    dispatch(hideMessage());
                    props.history.push('/');
                }}
            />}
        </div>
    );
};

export default ResetPassword;

