import {
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS, GET_ALL_REPORTS,
  GET_ALL_REPORTS_SUCCESS,
  GET_REPORT_BY_SITE,
  GET_REPORT_BY_SITE_SUCCESS,
  GET_REPORT_BY_DATE,
  GET_REPORT_BY_DATE_SUCCESS
} from "constants/ActionTypes";


export const getAllReports = () => {
  console.log("get all reports called:");
  return {
    type: GET_ALL_REPORTS
  };
};

export const getAllReportsSuccess = allReportss => {
  return {
    type: GET_ALL_REPORTS_SUCCESS,
    payload: allReportss
  };
};

export const getReportBySite = (siteObj) => {
  return {
    type: GET_REPORT_BY_SITE,
    siteObj
  };
};

export const getReportBySiteSuccess = reportsBySite => {
  return {
    type: GET_REPORT_BY_SITE_SUCCESS,
    payload: reportsBySite
  };
};
export const getReportByDate = (dateReport) => {
  return {
    type: GET_REPORT_BY_DATE,
    dateReport
  };
};

export const getReportByDateSuccess = reportsByDate => {
  return {
    type: GET_REPORT_BY_DATE_SUCCESS,
    payload: reportsByDate
  };
};


export const downloadReport = (stateCode) => {
  return {
    type: DOWNLOAD_REPORT,
    stateCode
  };
};

export const downloadReportSuccess = (stateCode, reportBlogData) => {
  return {
    type: DOWNLOAD_REPORT_SUCCESS,
    stateCode,
    reportBlogData
  };
};