import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import questionsSagas from './QuestionsSaga';
import reportsSagas from './ReportsSaga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        questionsSagas(),
        reportsSagas()
    ]);
}
