import { GET_ALL_QUESTIONS, GET_ALL_QUESTIONS_SUCCESS, GET_ALL_QUESTIONS_TYPE, GET_ALL_QUESTIONS_TYPE_SUCCESS, GET_ALL_LANGUAGE, GET_ALL_LANGUAGE_SUCCESS, GET_ALL_QUESTIONS_BY_ID, GET_ALL_QUESTIONS_BY_ID_SUCCESS, CLEAR_QUESTIONS_FROM_STORE, EDIT_QUESTIONS, EDIT_QUESTIONS_SUCCESS, POST_ALL_QUESTIONS, POST_ALL_QUESTIONS_SUCCESS, SHOW_QUESTION_SUCCESS_MESSAGE, SHOW_QUESTION_ERROR_MESSAGE, HIDE_QUESTION_MESSAGE, DELETE_QUESTION, DELETE_QUESTION_SUCCESS, GET_FORM_TYPES, GET_FORM_TYPES_SUCCESS, GET_SECTIONS, GET_SECTIONS_SUCCESS, GET_ALL_DATA_SOURCES, GET_ALL_DATA_SOURCES_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  allQuestions: [],
  allQuestionsById: null,
  questionSuccessMessage: "",
  questionErrorMessage: "",
  showMessage: false,
  allFormTypes: null,
  allSections: null,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_QUESTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ALL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        allQuestions: action.payload,
        loader: false,
      };
    }
    case GET_ALL_QUESTIONS_TYPE: {
      return {
        ...state
      };
    }
    case GET_ALL_QUESTIONS_TYPE_SUCCESS: {
      return {
        ...state,
        allType: action.payload
      };
    }
    case GET_ALL_LANGUAGE: {
      return {
        ...state
      };
    }
    case GET_ALL_LANGUAGE_SUCCESS: {
      return {
        ...state,
        allLanguage: action.payload
      };
    }
    case GET_ALL_QUESTIONS_BY_ID: {
      return {
        ...state
      };
    }
    case GET_ALL_QUESTIONS_BY_ID_SUCCESS: {
      return {
        ...state,
        allQuestionsById: action.payload
      };
    }
    case CLEAR_QUESTIONS_FROM_STORE: {
      return {
        ...state,
        allQuestionsById: null
      };
    }
    case EDIT_QUESTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case POST_ALL_QUESTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case POST_ALL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case DELETE_QUESTION: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case SHOW_QUESTION_SUCCESS_MESSAGE: {
      return {
        ...state,
        questionSuccessMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case SHOW_QUESTION_ERROR_MESSAGE: {
      return {
        ...state,
        questionErrorMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_QUESTION_MESSAGE: {
      return {
        ...state,
        questionSuccessMessage: "",
        questionErrorMessage: "",
        showMessage: false,
      };
    }
    case GET_FORM_TYPES: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FORM_TYPES_SUCCESS: {
      return {
        ...state,
        allFormTypes: action.payload,
        loader: false,
      };
    }
    case GET_SECTIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_SECTIONS_SUCCESS: {
      return {
        ...state,
        allSections: action.payload,
        loader: false,
      };
    }
    case GET_ALL_DATA_SOURCES: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ALL_DATA_SOURCES_SUCCESS: {
      return {
        ...state,
        allDataSources: action.payload,
        loader: false,
      };
    }
    default:
      return state;
  }
}