
export default {

    GET_ALL_QUESTIONS: 'questions ',
    GET_ALL_QUESTIONS_TYPE: 'answerMode',
    GET_ALL_LANGUAGE: 'Languages',
    POST_ALL_QUESTIONS: 'questions ',
    GET_ALL_REPORTS: 'patient',
    DOWNLOAD_REPORTS: 'patient/downloadToExcel',
    GET_ALL_QUESTIONS_BY_ID: 'questions',
    DELETE_QUESTION: 'questions',
    EDIT_QUESTIONS: 'questions ',
    GET_FORM_TYPES: 'formtypes',
    GET_SECTIONS: 'sections',
    GET_ALL_DATA_SOURCES: "common/getAllDataSources",
    SIGNIN_USER: "user/signin",
    GET_REPORT_BY_SITE: "patient/getBySite/",
    GET_REPORT_BY_DATE: "patient/getBySiteAndDate",
    CHANGE_PASSWORD: "user/changePass",
    FORGOT_PASSWORD: "user/forgotpassword",
    RESET_PASSWORD: "user/resetPass",
};