import {
  downloadReportSuccess,
  getAllReportsSuccess,
  getReportBySiteSuccess,
  getReportByDateSuccess
} from "actions/ReportsAction";
import axios from "axios";
import {
  DOWNLOAD_REPORT,
  GET_ALL_REPORTS,
  GET_REPORT_BY_SITE,
  GET_REPORT_BY_DATE
} from "constants/ActionTypes";
import {
  all,
  call,
  fork,
  put,
  takeEvery
} from "redux-saga/effects";
import {
  BASE_API_URL
} from "util/global";
import url from "util/url";


export function getAllReportsApi() {
  console.log("BASE_API_URL_ii", BASE_API_URL);
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_ALL_REPORTS
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET"
      // }
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
export function downloadReportsApi(district) {

  try {
    return axios({
      method: "post",
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      },
      url: BASE_API_URL + url.DOWNLOAD_REPORTS,
      data: {
        "district": district,
        "did": "string"
      },
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* getAllReportsFromServer() {
  console.log("Calling fetchAllReports wiht:-");
  try {
    const response = yield call(getAllReportsApi);
    console.log("fetchAllReports", response.data);
    if (response.data.error) { } else {
      yield put(getAllReportsSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);

  }
}

function* downloadReportsFromServer(action) {

  try {
    const response = yield call(downloadReportsApi, action.stateCode);
    console.log(response.data.type, "downloadReportsApi", response.data);
    if (response.data.error) { } else {
      // const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));

      yield put(downloadReportSuccess(action.stateCode, response.data));
    }
  } catch (error) {
    console.log("Exception", error);
  }
}
export function getReportBySiteApi(districtCode) {
  try {
    return axios({
      method: "get",
      url: BASE_API_URL + url.GET_REPORT_BY_SITE + districtCode,
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* getReportBySiteFromServer(action) {
  console.log("getReportBySiteFromServer", action.siteObj.districtCode);
  try {
    const response = yield call(getReportBySiteApi, action.siteObj.districtCode);
    console.log("fetchAllReports", response.data);
    if (response.data.error) {

    } else {
      yield put(getReportBySiteSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);

  }
}
export function getReportByDateApi(dateReport) {
  try {
    return axios({
      method: "post",
      url: BASE_API_URL + url.GET_REPORT_BY_DATE,
      data: dateReport,
    });
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

function* getReportByDateFromServer(action) {
  try {
    const response = yield call(getReportByDateApi, action.dateReport);
    console.log("fetchAllReports", response.data);
    if (response.data.error) {

    } else {
      yield put(getReportByDateSuccess(response.data));
    }
  } catch (error) {
    console.log("Exception", error);

  }
}

export function* fetchAllReportsSaga() {
  yield takeEvery(GET_ALL_REPORTS, getAllReportsFromServer);
  yield takeEvery(DOWNLOAD_REPORT, downloadReportsFromServer);
  yield takeEvery(GET_REPORT_BY_SITE, getReportBySiteFromServer);
  yield takeEvery(GET_REPORT_BY_DATE, getReportByDateFromServer);
}

export default function* rootSaga() {
  yield all([

    fork(fetchAllReportsSaga),

  ]);
}