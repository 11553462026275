import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
    return (
        <footer className="app-footer">
            <span className="d-inline-block">Copyright  &copy; {new Date().getFullYear()} All rights reserved.</span>
            <span className="d-inline-block"
        
            >Powered by
            <a href="https://digiflux.io/" target="_blank">
            <span 
            style={{color:'blue'}}> Digiflux IT Solutions
            </span>
            </a>
            </span>

        </footer>
    );
}
    ;

export default Footer;
