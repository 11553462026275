import {
  GET_ALL_QUESTIONS, GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_TYPE, GET_ALL_QUESTIONS_TYPE_SUCCESS, GET_ALL_LANGUAGE_SUCCESS, GET_ALL_LANGUAGE, POST_ALL_QUESTIONS, POST_ALL_QUESTIONS_SUCCESS, GET_ALL_QUESTIONS_BY_ID, GET_ALL_QUESTIONS_BY_ID_SUCCESS, DELETE_QUESTION, DELETE_QUESTION_SUCCESS, CLEAR_QUESTIONS_FROM_STORE, EDIT_QUESTIONS, EDIT_QUESTIONS_SUCCESS, SHOW_QUESTION_SUCCESS_MESSAGE, SHOW_QUESTION_ERROR_MESSAGE, HIDE_QUESTION_MESSAGE, GET_FORM_TYPES, GET_FORM_TYPES_SUCCESS, GET_SECTIONS, GET_SECTIONS_SUCCESS, GET_ALL_DATA_SOURCES, GET_ALL_DATA_SOURCES_SUCCESS
} from "constants/ActionTypes";


export const getAllQuestions = () => {
  return {
    type: GET_ALL_QUESTIONS
  };
};
export const getAllQuestionsSuccess = allQuestions => {
  return {
    type: GET_ALL_QUESTIONS_SUCCESS,
    payload: allQuestions
  };
};

export const getAllQuestionsType = () => {
  return {
    type: GET_ALL_QUESTIONS_TYPE
  };
};
export const getAllQuestionsTypeSuccess = allType => {
  // console.log("allType in actions", allType);
  return {
    type: GET_ALL_QUESTIONS_TYPE_SUCCESS,
    payload: allType
  }; 
};
export const getAllLanguage = () => {
  return {
    type: GET_ALL_LANGUAGE
  };
};
export const getAllLanguageSuccess = allLanguage => {
  console.log("all language in actions", allLanguage);
  return {
    type: GET_ALL_LANGUAGE_SUCCESS,
    payload: allLanguage
  };
};

export const addQuestion = addQuestionObj => {
  return {
    type: POST_ALL_QUESTIONS,
    addQuestionObj
  };
};
export const addQuestionSuccess = msg => {
  return {
    type: POST_ALL_QUESTIONS_SUCCESS,
    msg
  };
};

export const getQuestionsById = (questions_id) => {
  console.log("getQuestionsById in action => ", questions_id);
  return {
    type: GET_ALL_QUESTIONS_BY_ID,
    questions_id
  };
};
export const getQuestionsByIdSuccess = allQuestionsById => {
  console.log("Get all questions by id action => ", allQuestionsById);

  return {
    type: GET_ALL_QUESTIONS_BY_ID_SUCCESS,
    payload: allQuestionsById
  };
};

export const deleteQuestion = (question_id,callBack) => {
  return {
    type: DELETE_QUESTION,
    question_id,
    callBack
  };
};

export const deleteQuestionSuccess = msg => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    msg
  };
};

export const clearQuestionsFromStore = () => {
  return {
    type: CLEAR_QUESTIONS_FROM_STORE,
  };
};

export const editQuestion = editQuestionObj => {
  return {
    type: EDIT_QUESTIONS,
    editQuestionObj
  };
};
export const editQuestionSuccess = msg => {
  return {
    type: EDIT_QUESTIONS_SUCCESS,
    msg
  };
};

export const showQuestionSuccessMessage = questionSuccessMessage => {
  console.log("showQuestionSuccessMessage => ", questionSuccessMessage);
  return {
    type: SHOW_QUESTION_SUCCESS_MESSAGE,
    payload: questionSuccessMessage
  };
};

export const showQuestionErrorMessage = questionErrorMessage => {
  console.log("showQuestionErrorMessage => ", questionErrorMessage);
  
  return {
    type: SHOW_QUESTION_ERROR_MESSAGE,
    payload: questionErrorMessage
  };
};

export const hideQuestionMessage = () => {
  return {
    type: HIDE_QUESTION_MESSAGE
  };
};

export const getFormTypes = () => {
  return {
    type: GET_FORM_TYPES
  };
};
export const getFormTypesSuccess = allFormTypes => {
  return {
    type: GET_FORM_TYPES_SUCCESS,
    payload: allFormTypes
  };
};

export const getSections = () => {
  return {
    type: GET_SECTIONS
  };
};
export const getSectionsSuccess = allSections => {
  return {
    type: GET_SECTIONS_SUCCESS,
    payload: allSections
  };
};

export const getAllDataSources = () => {
  return {
    type: GET_ALL_DATA_SOURCES
  };
};
export const getAllDataSourcesSuccess = allDataSources=> {
  return {
    type: GET_ALL_DATA_SOURCES_SUCCESS,
    payload: allDataSources
  };
};