import { showAuthMessage, showSuccess, userSignInSuccess, userSignOutSuccess, forgotPasswordSuccess, resetPasswordSuccess } from "actions/Auth";
import Axios from "axios";
import {
    CHANGE_PASSWORD, SIGNIN_USER,
    SIGNOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD
} from "constants/ActionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { processErrorResponse } from "util/functions";
import { BASE_API_URL } from "util/global";
import url from "util/url";





function signInUserWithEmailPasswordApi(userObj) {
    try {
        return Axios({
            method: "post",
            url: BASE_API_URL + url.SIGNIN_USER,
            data: userObj,
        });
    } catch (error) {
        throw error;
    }
}

function* signInUserWithEmailPassword({ payload }) {
    const { email, password } = payload;
    const userObj = { email: email, password: password }
    try {
        const response = yield call(signInUserWithEmailPasswordApi, userObj);
        console.log("signInUserWithEmailPassword", response);
        if (response && response.data.data) {
            localStorage.setItem('token', response.data.data.accessToken);
            localStorage.setItem('Name', response.data.data.FullName);
            localStorage.setItem('Role', response.data.data.Role);
            localStorage.setItem('Location', response.data.data.Location);
            yield put(userSignInSuccess(response.data.data.accessToken));
        } else {
            yield put(showAuthMessage(response.data.message));
            console.log("response.data.error", response.data.error);
        }
    } catch (error) {
        console.log("error=====", error.message);
        yield put(showAuthMessage(error.response.data.message));
    }
}

function* signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('Name');
    localStorage.removeItem('Role');
    localStorage.removeItem('Location');
    yield put(userSignOutSuccess());
}
function changePasswordApi(passObj) {
    return Axios({
        method: "post",
        url: BASE_API_URL + url.CHANGE_PASSWORD,
        data: passObj,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
}

function* changePassword(action) {
    console.log("changePassword saga", action.passObj);
    try {
        const response = yield call(changePasswordApi, action.passObj);
        console.log("changePassword", response);
        if (response && response.status === 200) {
            yield put(showSuccess(response.data.message));
        } else {
            yield put(showAuthMessage(response.data.message));
            console.log("response.data.error", response.data);
        }
    } catch (error) {
        console.log("changePassword error=====", error.message);
        yield put(showAuthMessage(error.response.data.message));
        if (error.response.status == 403) {
            yield put(userSignOutSuccess(processErrorResponse(error.response)));
        }
    }
}
function forgotPasswordApi(emailObj) {
    return Axios({
        method: "post",
        url: BASE_API_URL + url.FORGOT_PASSWORD,
        data: emailObj,
    });
}

function* forgotPassword(action) {
    console.log("forgotPassword saga", action.emailObj);
    try {
        const response = yield call(forgotPasswordApi, action.emailObj);
        console.log("changePassword", response);
        if (response && response.status === 200) {
            yield put(forgotPasswordSuccess());
            yield put(showSuccess(response.data.message));
        } else {
            yield put(showAuthMessage(response.data.message));
        }
    } catch (error) {
        console.log("changePassword error=====", error.message);
        yield put(showAuthMessage(error.response.data.message));
    }
}
function resetPasswordApi(Obj) {
    return Axios({
        method: "post",
        url: BASE_API_URL + url.RESET_PASSWORD,
        data: Obj,
    });
}

function* resetPassword(action) {
    console.log("resetPassword saga", action.Obj);
    try {
        const response = yield call(resetPasswordApi, action.Obj);
        console.log("resetPassword", response);
        if (response && response.status === 200) {
            yield put(resetPasswordSuccess());
            yield put(showSuccess(response.data.message));
        } else {
            yield put(showAuthMessage(response.data.message));
        }
    } catch (error) {
        console.log("changePassword error=====", error.message);
        yield put(showAuthMessage(error.response.data.message));
    }
}
export function* resetPasswordSaga() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}
export function* forgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}
export function* changePasswordSaga() {
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}
export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
    fork(changePasswordSaga),
    fork(signOutUser),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    ]);
}