import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    SHOW_SUCCESS_MESSAGE,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    successMessage: "",
    showMessage: false,
    showSuccessMessage: false,
    initURL: '',
    authUser: localStorage.getItem('token'),
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: '/app/dashboard',
                loader: false
            }
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case SHOW_SUCCESS_MESSAGE: {
            return {
                ...state,
                successMessage: action.payload,
                showSuccessMessage: true,
                loader: false
            };
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                successMessage: "",
                showSuccessMessage: false,
                showMessage: false,
                loader: false
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case CHANGE_PASSWORD: {
            return {
                ...state,
                loader: true,
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case FORGOT_PASSWORD: {
            return {
                ...state,
                loader: true,
            };
        }
        case FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case RESET_PASSWORD: {
            return {
                ...state,
                loader: true,
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
